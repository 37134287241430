<template>
  <b-container class="container-rda">
    <b-row>
      <b-col class="content">
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>

        <b-row class="mb-5">
          <b-col md="9">
            <h2 class="purple bold">Registro de Aprendizagem</h2>
          </b-col>
          <b-col md="3">
            <b-button class="large blue outline" :to="{
              name: 'conteudos',
              params: {
                subject: this.$route.params.subject_id,
              },
            }">Voltar</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-row class="profile-row">
              <b-col>
                <img v-if="studentPhoto == null || ''" src="@/assets/images/default-photo.png" height="64" width="64"
                  alt />
                <img v-else width="64" height="64" :src="studentPhoto" alt />

                <div class="content-meeting">
                  <div class="column">
                    <h5>{{ studentName }}</h5>
                    <p>
                      <strong>Disciplina:</strong>
                      {{
                        subject && subject.designation
                          ? subject.designation
                          : ""
                      }}
                    </p>
                  </div>

                  <div class="column two">
                    <p>
                      <strong>Ciclo:</strong>
                      {{ cycleYear }}º ano do
                      {{ cycle && cycle.name ? cycle.name : "" }}
                    </p>
                    <p>
                      <strong>Evolução em relação ao currículo:</strong>
                      <span class="learning-progress">
                        {{
                          subject && subject.percentage
                            ? subject.percentage
                            : ""
                        }}%
                        <b-progress :value="subject && subject.percentage
                          ? subject.percentage
                          : ''
                          " class="white"></b-progress>
                      </span>
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="content-meeting">
                  <div class="column" style="padding-top: 30px">
                    <p><strong>Legenda:</strong></p>
                    <p><strong>A:</strong> Conhecimento aprendido</p>
                    <p>A<strong>N:</strong> Conhecimento ainda não aprendido</p>
                    <p>
                      <strong>*:</strong> Conhecimento supostamente alcançado em
                      outro estabelecimento de ensino
                    </p>
                    <p><strong>#:</strong> Dispensa de conteúdo curricular</p>
                    <p><strong>EE:</strong> Em estudo</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <template v-if="subject && subject.Competences && subject.Competences.length">
          <SelectSingle v-model="selectedCycle" :options="cyclesList" :callback="(event) => changeCycle(event)" />

          <b-modal id="modal-evidence" hide-footer style="display: flex; justify-content: center; align-items: center">
            <h3>Pendências</h3>

            <div>
              <b-form-textarea style="padding: 15px" id="textarea" v-model="pendencyText" rows="3"
                max-rows="6"></b-form-textarea>
            </div>

            <b-button v-if="!showSpinner" style="margin-top: 20px" class="evidence-btn" @click="savePendencie">Registrar
              Pendência</b-button>
            <div style="padding-top: 20px" v-else>
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-modal>

          <div v-for="(cycle, index) in filteredObj" :key="index" :id="`Tab-${index}`" :title="cycle.designation">
            <div class="viewRDA">
              <b-row>
                <b-col cols="12">
                  <div class="title-viewRDA" :id="`Title-${cycle.id}`">
                    <b>{{ cycle.Subjects[0].designation }}</b>
                  </div>
                </b-col>
              </b-row>
              <b-row :id="`List-${cycle.id}-${item.id}`" class="rda-viewlist"
                v-for="(item, competenceIndex) in cycle.Subjects[0].Competences"
                :key="`item-${cycle.id}-${competenceIndex}`">
                <b-col cols="12">
                  <b-row>
                    <b-col cols="8">
                      <h5 class="title" :id="`Subject-${item.description}`">
                        {{ competenceIndex + 1 }} - {{ item.description }}
                      </h5>
                    </b-col>
                    <b-col class="title" cols="2"> Status </b-col>
                    <b-col class="title" cols="1"> Avaliar </b-col>
                    <b-col class="title" cols="1"> Evidência </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" v-for="(topic, topicIndex) in item.Topics" :key="`student-${topicIndex}`">
                  <b-row class="list-teacher item-rda-viewlist">
                    <b-col cols="8" class="text-col">
                      <p>
                        {{ competenceIndex + 1 }}.{{ topicIndex + 1 }}
                        {{ topic.description }}
                      </p>
                      <div class="prof-data" v-if="topic.TopicStudents[0].editor_user_id !== null">
                        <span>Atualizado por
                          <b>{{ topic.TopicStudents[0].editor.name }}</b></span>
                        <time>
                          {{ formatDate(topic.TopicStudents[0].updated_at) }}
                        </time>
                      </div>
                    </b-col>

                    <b-button :id="topic.TopicStudents[0].topic_id" class="evidence-btn" v-if="
                      topic.TopicStudents[0].status === 'AN' &&
                      checkIfRoleIsHigh()
                    " @click="
                      registerEvidence(
                        topic.TopicStudents[0].topic_id,
                        topic.TopicStudents[0].student_id,
                        topic.TopicStudents[0].pendency
                      )
                      ">
                      Pendência
                    </b-button>

                    <b-col cols="2">
                      <div class="status-grid">
                        <span v-for="(status, index) in learningStatus" :key="index" :id="`Status-${topic.id}`" @click="(canEditAStatus || topic.TopicStudents[0].status !== 'A') ? updateTopicSingle({
                          status,
                          topic_id: topic.id,
                          current: topic.TopicStudents[0].status,
                        }) : null"
                          :style="(!canEditAStatus && topic.TopicStudents[0].status === 'A') ? 'cursor: not-allowed' : 'cursor: pointer'">
                          <p :id="`Upload-${topic.TopicStudents[0].id}`"
                            :class="`tags ${topic.TopicStudents[0].status === status ? 'warning' : 'info'}`">
                            {{ status }}
                          </p>
                        </span>
                      </div>
                    </b-col>

                    <b-col cols="1">
                      <div class="actions-grid">
                        <span :id="`Evaluation-${topic.id}`" @click="
                          updateEvaluationTopic({
                            topic_id: topic.id,
                            status: topic.TopicStudents[0].is_for_evaluation,
                          })
                          ">
                          <RateIcon :id="`EvaluationIconNew-${topic.id}-${index}`"
                            :ref="`evaluationIconRefNew_${topic.id}_${index}`" class="pointer" :class="{
                              inactive:
                                !topic.TopicStudents[0].is_for_evaluation,
                            }" />
                        </span>
                      </div>
                    </b-col>

                    <b-col cols="1">
                      <div class="actions-grid">
                        <label class="pointer">
                          <LearningUploadIcon :id="'addFileInputNew_' + index + '_' + index"
                            :ref="'addFileInputRefNew_' + index + '_' + index" />
                          <input hidden @change="() =>
                            uploadEvidence(index, topic.id, 'addFileInput_')
                            " type="file"
                            accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
                            name="file" :id="'addFileInput_' + index" :ref="'addFileInput_' + index" />
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>

        <template v-if="
          isRequestSend &&
          subject &&
          subject.Competences &&
          subject.Competences.length <= 0
        ">
          <p class="no-competence">
            Nenhuma competêcia cadastrada pra essa matéria.
          </p>
        </template>

        <template v-if="!isRequestSend">
          <p class="no-competence">
            Carregando
            <span class="spinner-border spinner-border-sm"></span>
          </p>
        </template>

        <b-row class="mt-5 mb-4" v-if="false">
          <b-col>
            <b-button class="full outline" @click="batchUpdateTopics('A')">Alterar Status para Aprendido</b-button>
          </b-col>

          <b-col>
            <b-button class="full" @click="batchUpdateTopics('*')">Registrar * para todos os conteúdos do 1
              ano</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LearningUploadIcon from "@/components/svg/LearningUploadIcon.vue";
import RateIcon from "@/components/svg/RateIcon.vue";
import SelectSingle from "../../components/inputs/SelectSingle.vue";
import httpRda from "../../http-rda";
import moment from "moment-timezone";
export default {
  name: "registrar-aprendizagens",
  components: {
    LearningUploadIcon,
    RateIcon,
    SelectSingle,
  },

  data() {
    return {
      canEditAStatus: false,
      showSpinner: false,
      isRequestSend: false,
      topicId: null,
      studentId: null,
      pendencyText: null,
      cyclesList: [],
      selectedCycle: null,
      othersCyclesSubject: [],
      filteredObj: null,
      subject: { Competences: [] },
      learningStatus: ["EE", "AN", "A", "*", "#"],
      breadcrumb: [
        {
          text: "Aprendizagem",
          href: "/aprendizagem",
        },
        {
          text: "Registro de Aprendizagem",
          href: "/aprendizagem/conteudos",
        },
        {
          text: "Aluno",
          href: "/aprendizagem/registrar-aprendizagens",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      student: "learning/student",
      subjectList: "learning/subjectContent",
    }),

    student_id() {
      return this.$route.params.id;
    },

    studentName() {
      if (!this.student.User) return "";
      return this.student.User.name;
    },

    studentPhoto() {
      if (!this.student.User) return "";
      return this.student.User.photo;
    },

    cycle() {
      if (!this.subject || !this.subject.Cycle) return "";
      return this.subject.Cycle;
    },

    cycleYear() {
      return this.student.year_id ? this.student.year_id : "";
    },

    orderbySort() {
      // return [...this.subject.Competences].sort((a, b) => {
      //   const order = this.descOrder ? [1, -1] : [-1, 1];

      //   if (a.sort_order < b.sort_order) return order[0];
      //   else if (a.sort_order > b.sort_order) return order[1];

      //   return 0;
      // });
      return [...this.subject.Competences];
    },
  },

  methods: {
    ...mapActions({
      loadSubjectContent: "learning/loadSubjectContent",
      getStudent: "learning/getStudent",
      updateStudentTopic: "learning/updateStudentTopic",
      updateStudentTopicEvaluation: "learning/updateStudentTopicEvaluation",
      updateEvidence: "learning/updateStudentFileTopic",
    }),

    formatDate(date) {
      return moment(date)
        .tz("America/Sao_Paulo")
        .format("DD/MM/YYYY [às] HH:mm");
    },

    async savePendencie() {
      this.showSpinner = true;
      await this.updateStudentTopic({
        student_id: this.studentId,
        topic_id: this.topicId,
        pendency: this.pendencyText,
      });
      this.showSpinner = false;
      await this.setSubject();
      this.$bvModal.hide("modal-evidence");
    },

    async updateEvaluationTopic({ topic_id, status }) {
      if (!status) return;
      await this.updateStudentTopicEvaluation({
        student_id: this.student_id,
        topic_id,
        is_for_evaluation: false,
      });

      await this.setSubject();
    },

    updateTopic({ topic_id, ...data }) {
      const getStatus = data.status === data.current ? "" : data.status;

      this.updateStudentTopic({
        student_id: this.student_id,
        topic_id,
        status: getStatus,
      });

      return this.updateStudentTopicEvaluation({
        student_id: this.student_id,
        topic_id,
        is_for_evaluation: false,
      });
    },

    registerEvidence(topicId, studentId, pendency) {
      this.topicId = topicId;
      this.studentId = studentId;
      this.pendencyText = pendency;

      this.$bvModal.show("modal-evidence");
    },

    checkIfRoleIsHigh() {
      return this.currentUser.role.some(
        (r) => r === "coordinator" || r === "teacher" || r === "secretary"
      );
    },

    async updateTopicSingle(params) {
      if (params.current === "A" && !this.canEditAStatus) {
        return;
      }
      const getStatus = params.status !== params.current ? params.status : "";

      await this.updateTopic(params);

      await this.setSubject();

      if (getStatus) {
        await this.successMessage("Status atualizado com sucesso!");
      } else {
        await this.successMessage("Status removido com sucesso!");
      }
    },

    changeCycle(data) {
      if (data && data.id) {
        if (data.id !== this.cycle.id) {
          alert("Você está saindo do ciclo atual do aluno");
        }
        this.selectedCycle = data.id;
      }
    },

    async batchUpdateTopics(status) {
      const allTopics = this.subject.Competences.map((c) => c.Topics).flat();
      await allTopics.map((topic) =>
        this.updateTopic({ topic_id: topic.id, status })
      );

      this.setSubject();

      setTimeout(this.setSubject, 1000);
      this.successMessage("Tópicos atualizados com sucesso!");
    },

    async uploadEvidence(index, topic_id, ref) {
      const [file] = this.$refs[ref + index][0].files;
      const bodyFormData = new FormData();

      bodyFormData.append("file", file);

      try {
        this.loading();
        await this.updateEvidence({
          student_id: this.student_id,
          topic_id,
          file: bodyFormData,
        });

        this.loading(false);
        this.successMessage();
      } catch (error) {
        this.errorMessage();
        return error.response;
      }
    },

    async setSubject() {
      const { id, subject_id } = this.$route.params;

      const cycles = await httpRda
        .get(`/tutoring/student/${id}/cycles`)
        .then((resp) => resp.data.cycles);

      await httpRda
        .get(`/students/${id}/subjects/${subject_id}?include=topics,cycle`)
        .then((response) => {
          this.subject = response.data.subjects[0];
          this.othersCyclesSubject = response.data.otherSubjects;

          this.filteredObj = [];

          this.filteredObj = this.othersCyclesSubject.filter(
            (cycle) => cycle.id === this.selectedCycle
          );

          this.cyclesList = cycles.map((c) => {
            return {
              id: c.id,
              name: c.designation,
            };
          });

          if (!this.selectedCycle) {
            this.selectedCycle = this.cyclesList[0].id;
          }
        })
        .catch((error) => {
          return error;
        });

      this.isRequestSend = true;
    },

    successMessage(title = "Evidência enviada com sucesso!") {
      this.$toast.open({
        message: title,
        type: "success",
        duration: 2000,
      });
    },

    errorMessage() {
      this.$toast.open({
        message: "Não foi possivel atualizar!",
        type: "error",
        duration: 2000,
      });
    },

    loading(show = true) {
      if (!show) {
        return this.$swal.close();
      }
      this.$swal.fire({
        title: "Carregando arquivo...",
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
  },

  watch: {
    selectedCycle: {
      handler() {
        this.filteredObj = [];

        this.filteredObj = this.othersCyclesSubject.filter(
          (cycle) => cycle.id === this.selectedCycle
        );
      },
    },
  },

  async created() {
    const { id, subject_id } = this.$route.params;

    if (!id || !subject_id) return this.$router.push("/");

    await this.setSubject();
    await this.getStudent(+id);
    this.canEditAStatus = this.currentUser.role.some(
      (r) => r === "coordinator" || r === "admin")
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.evidence-btn {
  border-radius: 5px;
  background-color: #68c5b7;
  color: white;
  border: none;
}

.info {
  background-color: #b3b3b3;
}

.status-grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
  }
}

.actions-grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-content {
  .nav-tabs {
    border-bottom: 2px solid #e6e6e6;
    gap: 24px;

    li {
      &:first-child {
        a {
          text-align: left;
          padding: 0;
        }
      }
    }

    .nav-link {
      text-align: center;
      font-size: 18px;
      color: #1a1a1a;
      height: 52px;
      border: 0;
      padding: 0 18px;
      position: relative;

      &.active {
        font-weight: bold;
        background: transparent;

        &:after {
          width: 100%;
        }
      }

      &:after {
        background: $gradient1;
        content: "";
        width: 0;
        height: 4px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50px;
        transition: 0.2s all ease-in-out;
      }
    }
  }
}

ul,
li {
  padding: 0;
}

.home {
  height: 100%;
}

.blue {
  color: $blue;
}

.prof-data {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 4px;

  span {
    font-size: 12px;
    color: #939393;

    b {
      font-weight: bold;
    }
  }

  time {
    font-size: 10px;
    color: #939393;
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  margin: 30px 0 60px;
  padding: 28px 10px;
  position: relative;

  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }

  .col {
    display: flex;
    align-items: center;

    img {
      margin-right: 40px;
    }

    .content-meeting {
      display: flex;
      width: 100%;

      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 60px;
        min-width: 250px;
        margin-right: 30px;

        &.two {
          min-width: 650px;
        }

        .learning-progress {
          display: inline-flex;
          align-items: center;
          margin-left: 10px;
          width: 50%;

          .progress {
            width: 100%;
            margin-left: 15px;

            &.white {
              background-color: transparent;
              border: 1px solid $white;

              .progress-bar {
                background-color: $white;
              }
            }
          }
        }
      }

      h5 {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }
  }
}

.progress {
  border-radius: 30px;
  height: 7px;

  .progress-bar {
    background-color: $purple;
  }

  &.orange {
    .progress-bar {
      background-color: $orange2;
    }
  }

  &.yellow {
    .progress-bar {
      background-color: $yellow;
    }
  }

  &.red {
    .progress-bar {
      background-color: $red;
    }
  }

  &.green {
    .progress-bar {
      background-color: $green2;
    }
  }
}

.container-rda {
  padding-bottom: 0;
}

.learning-date {
  background-color: $purple;
  border-radius: 6px;
  color: $white;
  line-height: 28px;
  max-width: 82px;
  text-align: center;
  width: 100%;
}

.learning-tags {
  font-size: 0;

  span {
    background-color: $grey5;
    border-radius: 6px;
    color: $white;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    margin-right: 12px;
    max-width: 34px;
    width: 100%;
    text-align: center;
    // pointer-events: none;

    &.active {
      background-color: $blue;
    }

    &.active+span {
      cursor: pointer;
      // pointer-events: unset;
    }
  }
}

.pointer {
  cursor: pointer;
}

.comp-list {
  &+.comp-list {
    margin-top: 30px;
  }
}

.no-competence {
  max-width: 300px;
  text-align: center;
  margin: auto;
  font-size: 1.3rem;
  opacity: 0.7;
  line-height: 1.2;
}

.swal2-loading .swal2-confirm {
  display: none;
}

.inactive.pointer {
  filter: grayscale(1);
  cursor: unset;
}
</style>
